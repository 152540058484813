import {UserType} from '../types/user.type';

export class UserUtil {

    static isAdmin(user?: UserType): boolean {
        return this.hasAdminRole(user?.roles);
    }

    static isRedactor(user?: UserType): boolean {
        return this.hasRedactorRole(user?.roles);
    }

    static isAdminOrRedactor(user?: UserType): boolean {
        return this.isInRole(['ADMIN', 'REDAKTOR'], user?.roles);
    }

    static hasAdminRole(roles?: string[]): boolean {
        return ['ADMIN'].every((role) => roles?.includes(role));
    }

    static hasRedactorRole(roles?: string[]): boolean {
      return ['REDAKTOR'].every((role) => roles?.includes(role));
    }

    static hasAdminOrRedactorRole(roles?: string[]): boolean {
        return ['ADMIN', 'REDAKTOR'].some((role) => roles?.includes(role));
    }

    static isInRole(requiredRoles: string[], roles?: string[]): boolean {
        return roles != undefined && requiredRoles.some((role) => roles.includes(role));
    }

    static getDefaultRoute(roles: string[]): string {
        if (this.isInRole(['ADMIN', 'REDAKTOR'], roles)) {
            return '/admin';
        } else {
            return '/content';
        }
    }
}
