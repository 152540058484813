/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';

import { AttachmentListResponse } from '../models/attachment-list-response';
import { ContentDetail } from '../models/content-detail';
import { ContentSearchResponse } from '../models/content-search-response';
import { createPlayAudit } from '../fn/content/create-play-audit';
import { CreatePlayAudit$Params } from '../fn/content/create-play-audit';
import { getActualContent } from '../fn/content/get-actual-content';
import { GetActualContent$Params } from '../fn/content/get-actual-content';
import { getContent } from '../fn/content/get-content';
import { GetContent$Params } from '../fn/content/get-content';
import { getContentAttachment } from '../fn/content/get-content-attachment';
import { GetContentAttachment$Params } from '../fn/content/get-content-attachment';
import { getContentAttachmentList } from '../fn/content/get-content-attachment-list';
import { GetContentAttachmentList$Params } from '../fn/content/get-content-attachment-list';
import { getVideoStream } from '../fn/content/get-video-stream';
import { GetVideoStream$Params } from '../fn/content/get-video-stream';
import { searchContent } from '../fn/content/search-content';
import { SearchContent$Params } from '../fn/content/search-content';
import { Transporter } from '../models/transporter';


/**
 * Služby pre načítanie stránok
 */
@Injectable({ providedIn: 'root' })
export class ContentService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `searchContent()` */
  static readonly SearchContentPath = '/client/content/search';

  /**
   * Služba pre hladanie stránok.
   *
   * Služba pre hladanie stránok
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `searchContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchContent$Response(params: SearchContent$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentSearchResponse>> {
    return searchContent(this.http, this.rootUrl, params, context);
  }

  /**
   * Služba pre hladanie stránok.
   *
   * Služba pre hladanie stránok
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `searchContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  searchContent(params: SearchContent$Params, context?: HttpContext): Observable<ContentSearchResponse> {
    return this.searchContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentSearchResponse>): ContentSearchResponse => r.body)
    );
  }

  /** Path part for operation `getContent()` */
  static readonly GetContentPath = '/client/content/{id}';

  /**
   * Služba pre načítanie strán.
   *
   * Služba pre načítanie stránky  podľa id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent$Response(params: GetContent$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentDetail>> {
    return getContent(this.http, this.rootUrl, params, context);
  }

  /**
   * Služba pre načítanie strán.
   *
   * Služba pre načítanie stránky  podľa id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContent(params: GetContent$Params, context?: HttpContext): Observable<ContentDetail> {
    return this.getContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentDetail>): ContentDetail => r.body)
    );
  }

  /** Path part for operation `getActualContent()` */
  static readonly GetActualContentPath = '/client/actualContent/{id}';

  /**
   * Služba pre načítanie najnovšej verzie obsahu.
   *
   * Služba pre načítanie najnovšej verzie obsahu podľa id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getActualContent()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActualContent$Response(params: GetActualContent$Params, context?: HttpContext): Observable<StrictHttpResponse<ContentDetail>> {
    return getActualContent(this.http, this.rootUrl, params, context);
  }

  /**
   * Služba pre načítanie najnovšej verzie obsahu.
   *
   * Služba pre načítanie najnovšej verzie obsahu podľa id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getActualContent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getActualContent(params: GetActualContent$Params, context?: HttpContext): Observable<ContentDetail> {
    return this.getActualContent$Response(params, context).pipe(
      map((r: StrictHttpResponse<ContentDetail>): ContentDetail => r.body)
    );
  }

  /** Path part for operation `getContentAttachmentList()` */
  static readonly GetContentAttachmentListPath = '/client/content/{id}/downloadAttachment';

  /**
   * Služba pre načítanie príloh.
   *
   * Služba pre načítanie zpznamu príloh pre danú stránku podľa id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContentAttachmentList()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentAttachmentList$Response(params: GetContentAttachmentList$Params, context?: HttpContext): Observable<StrictHttpResponse<AttachmentListResponse>> {
    return getContentAttachmentList(this.http, this.rootUrl, params, context);
  }

  /**
   * Služba pre načítanie príloh.
   *
   * Služba pre načítanie zpznamu príloh pre danú stránku podľa id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContentAttachmentList$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentAttachmentList(params: GetContentAttachmentList$Params, context?: HttpContext): Observable<AttachmentListResponse> {
    return this.getContentAttachmentList$Response(params, context).pipe(
      map((r: StrictHttpResponse<AttachmentListResponse>): AttachmentListResponse => r.body)
    );
  }

  /** Path part for operation `getContentAttachment()` */
  static readonly GetContentAttachmentPath = '/client/content/{id}/downloadAttachment/{attachmentId}';

  /**
   * Služba pre načítanie prílohy.
   *
   * Služba pre načítanie prílohy pre danú stránku podľa id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getContentAttachment()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentAttachment$Response(params: GetContentAttachment$Params, context?: HttpContext): Observable<StrictHttpResponse<Transporter>> {
    return getContentAttachment(this.http, this.rootUrl, params, context);
  }

  /**
   * Služba pre načítanie prílohy.
   *
   * Služba pre načítanie prílohy pre danú stránku podľa id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getContentAttachment$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getContentAttachment(params: GetContentAttachment$Params, context?: HttpContext): Observable<Transporter> {
    return this.getContentAttachment$Response(params, context).pipe(
      map((r: StrictHttpResponse<Transporter>): Transporter => r.body)
    );
  }

  /** Path part for operation `getVideoStream()` */
  static readonly GetVideoStreamPath = '/client/content/{id}/version/{versionId}/streamVideo/{attachmentId}';

  /**
   * Služba video streaming.
   *
   * Služba pre video streaming pre danú stránku podľa id
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getVideoStream()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVideoStream$Response(params: GetVideoStream$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
    return getVideoStream(this.http, this.rootUrl, params, context);
  }

  /**
   * Služba video streaming.
   *
   * Služba pre video streaming pre danú stránku podľa id
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getVideoStream$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getVideoStream(params: GetVideoStream$Params, context?: HttpContext): Observable<string> {
    return this.getVideoStream$Response(params, context).pipe(
      map((r: StrictHttpResponse<string>): string => r.body)
    );
  }

  /** Path part for operation `createPlayAudit()` */
  static readonly CreatePlayAuditPath = '/client/eventAuditEvidence/createPlay';

  /**
   * Uloženie auditnej udalosti prehrania videa.
   *
   * Služba uloženia auditnej udalosti pre prehrania videa
   *
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createPlayAudit()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPlayAudit$Response(params?: CreatePlayAudit$Params, context?: HttpContext): Observable<StrictHttpResponse<boolean>> {
    return createPlayAudit(this.http, this.rootUrl, params, context);
  }

  /**
   * Uloženie auditnej udalosti prehrania videa.
   *
   * Služba uloženia auditnej udalosti pre prehrania videa
   *
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createPlayAudit$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createPlayAudit(params?: CreatePlayAudit$Params, context?: HttpContext): Observable<boolean> {
    return this.createPlayAudit$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

}
