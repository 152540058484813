import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {MenuSection} from '../../shared/openapi/client/models/menu-section';
import {Utils} from '../../shared/utils/utils';

@Injectable()
export class ContentMenuService {

    selectedPath: number[] | undefined;
    loadMenuContentListSubject = new Subject<MenuSection | undefined>();
    contactsMenuPathSubject = new Subject<string | undefined>();

    isActivePath(menuPath: number[] | undefined): boolean {
        if (Utils.isNotEmpty(this.selectedPath)) {
            return (this.selectedPath?.toString() + ',').startsWith(menuPath!.toString() + ',') || false;
        }
        return false;
    }

    getLastSectionId(): number | undefined {
        return Utils.getLastParam(this.selectedPath);
    }

    /**
     * Metoda rekurzivne prehladava menu a podla id/code kontentu hlada zvolenu sekciu
     * @param sections - menu
     * @param id
     * @param code
     */
    findRecursiveMenuSection(sections: Array<MenuSection> | undefined, id: number | undefined, code?: string | undefined): MenuSection | undefined {
        if (!sections || !(id || code)) { return undefined; }

        for (const item of sections) {
            if (id && item.id === id) { return item; }
            if (code && item.code === code) { return item; }

            const child: MenuSection | undefined = this.findRecursiveMenuSection(item.subSections, id, code);
            if (child) { return child; }
        }

        return undefined;
    }
}
