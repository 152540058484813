/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StrictHttpResponse } from '../../strict-http-response';
import { RequestBuilder } from '../../request-builder';


export interface GetVideoStream$Params {
  Range?: string;

/**
 * ID sekcie
 */
  id: number;

/**
 * ID verzie
 */
  versionId: number;

/**
 * ID prílohy
 */
  attachmentId: number;
}

export function getVideoStream(http: HttpClient, rootUrl: string, params: GetVideoStream$Params, context?: HttpContext): Observable<StrictHttpResponse<string>> {
  const rb = new RequestBuilder(rootUrl, getVideoStream.PATH, 'get');
  if (params) {
    rb.header('Range', params.Range, {});
    rb.path('id', params.id, {});
    rb.path('versionId', params.versionId, {});
    rb.path('attachmentId', params.attachmentId, {});
  }

  return http.request(
    rb.build({ responseType: 'blob', accept: 'video/mp4', context })
  ).pipe(
    filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
    map((r: HttpResponse<any>) => {
      return r as StrictHttpResponse<string>;
    })
  );
}

getVideoStream.PATH = '/client/content/{id}/version/{versionId}/streamVideo/{attachmentId}';
